import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <h1>This page hasn&apos;t sprouted yet.</h1>
    <p>We&apos;re sorry, but there&apos;s not a page here. Please make sure the link you followed looks correct.</p>
    <p>
      <Link to='/'>Return to Plug Trays home</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
